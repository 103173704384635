import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="navOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper1">
                <div id="block-menu-primary-links" className="block block-menu">
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first home">
                        <a href="/home" title="Home">
                          <img src="/sites/migraleve.co.uk/files/images/home.png" />
                        </a>
                      </li>
                      <li className="expanded aboutmigraines">
                        <a href="/about-migraine" title="About Migraines" className="about-migraine">
                          ABOUT MIGRAINES
                        </a>
                        <ul className="menu">
                          <li className="leaf first migrainesymptoms">
                            <a href="/migraine-symptoms" title="Migraine Types & Symptoms">
                              Migraine Types & Symptoms
                            </a>
                          </li>
                          <li className="leaf potentialmigrainecauses">
                            <a
                              href="/migraine-causes"
                              title="Potential Migraine Causes"
                            >
                              Potential Migraine Causes
                            </a>
                          </li>
                          <li className="leaf last whatcanyoudoaboutit">
                            <a
                              href="/migraine-treatment-and-prevention"
                              title="What Can You Do About It ?"
                            >
                              What Can You Do About It ?
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="leaf ourproducts">
                        <a
                          href="/migraine-relief-products"
                          title="Our Products"
                        >
                          OUR
                          <br /> PRODUCTS
                        </a>
                      </li>
                      <li className="leaf migrainemanagementtips">
                        <a
                          href="/migraine-tips"
                          title="Migraine Management Tips"
                        >
                          MIGRAINE MANAGEMENT TIPS
                        </a>
                      </li>
                      <li className="leaf migrainetriggerdiary">
                        <a
                          href="/migraine-trigger-diary"
                          title="Migraine Trigger Diary"
                        >
                          MIGRAINE TRIGGER DIARY
                        </a>
                      </li>
                      <li className="leaf last wheretobuy">
                        <a href="/where-to-buy" title="Where to buy">
                          WHERE TO BUY
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contentOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div className="homeBannerWrapper">
                  <div className="innerPageContentWrapper">
                    <div className="innerPageContent privacy-n-Legal">
                      <div id="node-4" className="node">
                        <div className="content clear-block">
                          <h1>Contact Us</h1>
                          <h2>Give us a call</h2>
                          <p>
                            <b>
                              If you have a medical emergency or if you need
                              specific medical advice, you should contact your
                              doctor or your local hospital.
                            </b>
                            <br /> If you would like to speak to someone in
                            person, please contact us on the phone number listed
                            below.
                          </p>
                          <p>
                            For medical and product usage enquiries call: <br />
                            <b>01344 864042</b>
                          </p>
                          <p>
                            For product quality issues and feedback call: <br />
                            <b>01344 864042</b>
                          </p>
                          <p>
                            <strong>
                              If you would like to contact us in writing please
                              use the following address:
                            </strong>
                          </p>
                          <ul>
                            <li>
                              <b>Ref: Migraleve.co.uk</b>
                              <br /> &nbsp;
                            </li>
                            <li>Johnson &amp; Johnson Limited</li>
                            <li>50-100 Holmers Farm Way,</li>
                            <li>High Wycombe</li>
                            <li>Buckinghamshire, HP12 4DP</li>                      
                          </ul>
                          <ul>
                            <li>
                              Please{" "}
                              <a
                                href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pJ"
                                target="_blank"
                              >
                                click here
                              </a>{" "}
                              to contact us.
                            </li>
                          </ul>
                        </div>
                        <div className="clear-block">
                          <div className="meta"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div className="footerContent">
                  <div
                    id="block-menu-menu-site-footer-menu"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="leaf first privacypolicy">
                          <a href="/privacy-policy" title="Privacy policy">
                            Privacy policy
                          </a>
                        </li>
                        <li className="leaf legalnotice">
                          <a href="/legal-notice" title="Legal notice">
                            Legal notice
                          </a>
                        </li>
                        <li className="leaf active-trail contactus">
                          <a
                            href="/contact-us"
                            title="Contact us"
                            className="active"
                          >
                            Contact us
                          </a>
                        </li>
                        <li className="leaf cookiepolicy">
                          <a href="/cookie-policy" title="Cookie Policy">
                            Cookie Policy
                          </a>
                        </li>
                        <li className="leaf modernslaveryactstatement">
                          <a
                            href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                            target="_blank"
                          >
                            Modern Slavery Act Statement
                          </a>
                        </li>
                        <li className="leaf last cookiesettings">
                          <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                            Cookie Settings
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="block-block-3" className="block block-block">
                    <div className="content">
                      <p>
                        <strong>
                          Always read the label. Ask your pharmacist for advice.
                        </strong>
                      </p>
                      <p>
                        MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                        codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                        codeine and buclizine. MIGRALEVE<em>™</em> Yellow
                        contains paracetamol and codeine.
                      </p>
                      <p>
                        Can cause addiction. For three days use only. This
                        medicine can make you feel sleepy. Do not drive while
                        taking this medicine until you know how it makes you
                        feel.
                      </p>
                      <p>
                        This site is published by Johnson &amp; Johnson Limited
                        which is solely responsible for its contents. It is
                        intended for a UK audience. MIGRALEVE<em>™</em> is a
                        registered trademark.
                      </p>
                      <p>
                        This site is best viewed with the following web
                        browsers: Internet Explorer version 8, Firefox version
                        2+, Safari 3+, Chrome version 3+.
                      </p>
                      <p>
                        © Johnson &amp; Johnson Limited 2024. This site was last
                        updated on: 15<sup>th </sup>August 2024
                      </p>
                    </div>
                  </div>
                  <p>
                    <a
                      id="popup-trigger"
                      href="/sites/migraleve.co.uk/files/pop-up-text"
                      rel="lightmodal"
                    >
                      hide this
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
